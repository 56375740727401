import React, { useContext } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import dayjs from 'dayjs'
import { SessionContainer, TitleRow, Title, Location } from './Session.sc'
import { Activity } from '../Activity/Activity'
import IconButton from '/components/IconButton/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { saveSession, deleteSession, createActivity } from '/actions/sessionActions'

export const Session = props => {
  const dispatch = useDispatch()
  const { sessionData, toggleEdit, edit } = props
  const { _id, title, location, activities } = sessionData
  const { time, date } = sessionData
  const formattedDate = dayjs(date).format('D.M')

  return (
    <SessionContainer>
      <TitleRow>
        <div>
          <Title
            value={title}
            label={'title'}
            placeholder="Session title"
            edit={edit}
          />
          <Location
            value={location}
            label={'location'}
            placeholder="Session location"
            edit={edit}
          />
        </div>
        <IconButton onClick={toggleEdit}>
          <FiEdit2 />
        </IconButton>
      </TitleRow>
      <p>{formattedDate}</p>
      <div>
        {activities &&
          activities.map((act, i) => {
            return (
              <Activity
                key={i}
                activityData={act}
                edit={edit}
                // saveSession={debouncedSaveSession}
              />
            )
          })}
      </div>
      {edit && (
        <>
          {/* <button onClick={() => dispatch(createActivity)}>add activity</button> */}
          <button onClick={() => dispatch(deleteSession(_id))}>delete mee</button>
        </>
      )}
    </SessionContainer>
  )
}
