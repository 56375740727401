import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  border: none;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`

const IconButton = props => {
  return <Button onClick={props.onClick}>
    {props.children}
  </Button>
}

export default IconButton
