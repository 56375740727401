import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { inputChange } from '/actions/sessionActions'

// http://jsfiddle.net/sphinxxx/sqaoet21/2/

const inputPadding = 4
const defaultWidth = '160px'

const editEnabled = `
  border-bottom: 1px solid rgba(0,0,0,0.1);

  &:focus {
    border-bottom: 1px solid #9FD5CA;
  }
`

const Input = styled.input`
  padding: ${inputPadding}px;
  margin-left: ${-inputPadding}px;
  margin-right: ${inputPadding}px;
  border: none;
  height: 1em;
  line-height: 1em;
  outline: 0;
  width: ${props => props.width ? props.width : defaultWidth};
  ${props => props.edit && editEnabled}
`

export const EditText = props => {
  const { placeholder, className, edit, label, value } = props
  const dispach = useDispatch()
  const [width, setWidth] = useState(defaultWidth)
  const inputEl = useRef(null)

  useEffect(() => {
    expand()
  }, [])

  const expand = () => {
    const input = inputEl.current
    if (!input) {
      return
    }
    input.style.width = 0
    const scrollWidth = `${input.scrollWidth - inputPadding * 2}px` // Subraction is inputPadding x2
    input.style.width = ''
    input.value ? setWidth(scrollWidth) : setWidth('160px')
  }

  const change = value => {
    if (edit) {
      dispach(inputChange(label, value))
      expand(value)
    }
  }

  const blur = () => {
    !edit && inputEl.current.blur()
  }

  return value || edit ? (
    <Input
      ref={inputEl}
      className={className}
      type="text"
      value={value ? value : ''}
      onChange={e => change(e.target.value)}
      onClick={blur}
      placeholder={placeholder}
      width={width}
      edit={edit}
    />
  ) : null
}
