import React from 'react'
import { ActivityRow, ActivityTitle, Description } from './Activity.sc'

export const Activity = props => {
  const { activityData, edit } = props
  const { title, description } = activityData

  return (
    <ActivityRow>
      <ActivityTitle edit={edit}>{title}</ActivityTitle>
      <Description edit={edit}>{description}</Description>
    </ActivityRow>
  )
}
