import React, { useEffect } from 'react'
import { SyncLoader } from 'react-spinners'
import { Frame } from './Main.sc'
import { useDispatch, useSelector } from 'react-redux'
import { Session } from '/components/Session/Session'
import {
  refreshSessions,
  createSession,
  setEditing
} from '/actions/mainActions'
import { setLoading } from '/actions/commonActions'

const Main = () => {
  const sessions = useSelector(state => state.sessions)
  const loading = useSelector(state => state.loading)
  const sessionInEdit = useSelector(state => state.sessionInEdit)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(refreshSessions())
  }, [])

  return (
    <Frame>
      {sessions.map((sesh, i) => {
        const id = sesh._id && sesh._id.toString()
        const edit = sessionInEdit === id
        return (
          <Session
            key={i}
            toggleEdit={() => dispatch(setEditing(edit ? null : id))}
            sessionData={sesh}
            edit={edit}
          />
        )
      })}
      <button onClick={() => dispatch(createSession())}>add sesh</button>
      {loading && (
        <SyncLoader
          size={50}
          color={'rgba(255,255,255,0.5)'}
          loading={loading}
        />
      )}
    </Frame>
  )
}

export default Main
