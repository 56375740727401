import React from 'react'
import { Provider } from 'react-redux'
import GlobalStyle from './global-style'
import Main from '/components/Main/Main'
import { H1 } from './App.sc'
import store from '/store'

const App = () => {
  return (
    <React.Fragment>
      <H1>RAINER</H1>
      <Provider store={store}>
        <Main />
      </Provider>
      <GlobalStyle />
    </React.Fragment>
  )
}

export default App
