export default {
  ADD_SESSION: 'ADD_SESSION',
  SET_SESSIONS: 'SET_SESSIONS',

  LOADING_PENDING: 'LOADING_PENDING',
  LOADING_DONE: 'LOADING_DONE',

  SET_EDITABLE_SESSION: 'SET_EDITABLE_SESSION',
  END_EDITING: 'END_EDITING',

  SESSION_INPUT_CHANGE: 'SESSION_INPUT_CHANGE',
  ACTIVITY_INPUT_CHANGE: 'ACTIVITY_INPUT_CHANGE',

  ACTIVITY_CREATE_PENDING: 'ACTIVITY_CREATE_PENDING',
}
