import { createGlobalStyle } from 'styled-components'
import { reset } from './resetCss'
import { theme } from '/components/variables'

const textBaseColor = theme.gray

export default createGlobalStyle`
  ${reset}
  body {
    background: ${theme.color};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'Noto Sans JP', 'sans';
    color: ${textBaseColor};
  }
  h1,h2,h3 {
    font-family: 'Quicksand', 'sans';
    margin: 8px 0;
    color: ${textBaseColor}
  }
  h1 {
    font-size: 4em;
    letter-spacing: -1;
    font-weight: 300;
  }
  h2 {
    font-size: 2.8em;
    font-weight: 400;
  }
  h3 {
    font-size: 2em;
    letter-spacing: 0.25;
  }
  h4 {
    font-size: 1.4em;
  }
  #root {
    width: 100%;
  }
  input {
    color: ${textBaseColor};
  }
  button {
    background: #fff;
    font-size: .95em;
    ${'' /* border: solid 2px ${theme.color}; */}
    border: solid 1px rgba(0,0,0,0.5);
    border-radius: 2px;
    padding: 8px;
    text-transform: uppercase;
    ${'' /* font-weight: 600; */}
    letter-spacing: -0.2px;
  }
`
