import { combineReducers } from 'redux'
import actions from '/actions/actionTypes'

const {
  ADD_SESSION,
  SET_SESSIONS,
  LOADING_PENDING,
  LOADING_DONE,
  SET_EDITABLE_SESSION,
  END_EDITING,
  SESSION_INPUT_CHANGE,
  ACTIVITY_CREATE_PENDING
} = actions

const initSessions = [
  // { title: 'ekasessio', location: 'böle' },
  // { title: 'Sportti', location: 'Bálcon' }
]

const sessionsReducer = (sessions = initSessions, action) => {
  switch (action.type) {
    case ADD_SESSION:
      return [...sessions, { title: 'kissasessio', location: 'kissamaa' }]
    case SET_SESSIONS:
      return action.sessions
    case SESSION_INPUT_CHANGE:
      const { session } = action
      return sessions.map(sesh =>
        sesh._id === session._id
          ? {
              ...session,
            }
          : sesh
      )
    case ACTIVITY_CREATE_PENDING:

    default:
      return sessions
  }
}

const loadingReducer = (loading = true, action) => {
  switch (action.type) {
    case LOADING_PENDING:
      return true
    case LOADING_DONE:
      return false
    default:
      return loading
  }
}

const editingReducer = (session = null, action) => {
  switch (action.type) {
    case SET_EDITABLE_SESSION:
      return action.session
    case END_EDITING:
      return null
    default:
      return session
  }
}

export default combineReducers({
  sessions: sessionsReducer,
  loading: loadingReducer,
  sessionInEdit: editingReducer,
})
