import { debounce } from 'lodash-es'
import actions from '/actions/actionTypes'
import apis from '/api/apis'
import { setLoading } from '/actions/commonActions'
import { refreshSessions, setEditing } from '/actions/mainActions'

export const saveSession = () => (dispatch, getState) => {
  const state = getState()
  const session = state.sessions.find(sesh => sesh._id === state.sessionInEdit)
  session.title && apis.updateSessionById(_id, session)
}

export const deleteSession = _id => async dispatch => {
  dispatch(setLoading())
  await apis.deleteSessionById(_id).catch(error => {
    console.log(error)
  })
  dispatch(refreshSessions())
  dispatch(setEditing(null))
}

export const updateSessionDebounced = debounce(session => {
  session && apis.updateSessionById(session._id, session)
}, 500)

export const inputChange = (label, value) => (dispatch, getState) => {
  const state = getState()
  const _id = state.sessionInEdit
  const sesh = state.sessions.find(session => session._id === _id)
  if (!sesh) {
    return
  }
  sesh[label] = value
  dispatch(sessionInputChange(sesh))
  updateSessionDebounced(sesh)
}

const sessionInputChange = session => {
  return { type: actions.SESSION_INPUT_CHANGE, session }
}

export const createActivity = () => {
  return { type: actions.ACTIVITY_CREATE_PENDING }
}
