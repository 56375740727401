import styled from 'styled-components'
import { fonts } from '/components/variables'
import { EditText } from '/components/EditText/EditText'

export const SessionContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
  padding: 8px;
  margin: 0.25em 0;
`

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  vertical-align: middle;
  justify-content: space-between;
`

export const Title = styled(EditText)`
  font-family: ${fonts.familyHeading};
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: -0.2px;
`

export const Location = styled(EditText)`
  font-family: ${fonts.familyHeading};
  font-size: 1em;
`
