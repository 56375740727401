import media from '/components/media'

export const theme = {
  color: '#9aeddc',
  colorDark: '#83c9bb',
  lightGray: '#474A48',
  gray: '#2C302E',
  black: '#202020'
}
export const fonts = {
  familyBody: `'Noto Sans JP', 'sans'`,
  familyHeading: `'Quicksand', 'sans'`
}

export default {
  media,
  fonts,
  theme
}
