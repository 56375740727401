export default {
  // Small devices (landscape phones, 576px and up)
  sm: `@media (min-width: 36em)`,

  // Medium devices (tablets, 768px and up)
  md: `@media (min-width: 48em)`,

  // Large devices (desktops, 992px and up)
  lg: `@media (min-width: 62em)`,

  // Extra large devices (large desktops, 1200px and up)
  xl: `@media (min-width: 75em)`,

  maxSm: `@media (max-width: 575.98px)`,

  // Small devices (landscape phones, less than 768px)
  maxMd: `@media (max-width: 767.98px)`,

  // Medium devices (tablets, less than 992px)
  maxLg: `@media (max-width: 991.98px)`,

  // Large devices (desktops, less than 1200px)
  maxXl: `@media (max-width: 1199.98px)`
}
