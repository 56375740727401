import styled, { keyframes } from 'styled-components'
import media from '/components/media'

export const MainContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  margin: 20px;
  width: 100%;
`
export const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: 0 8px;
  ${media.sm} {
    padding: 0 12px;
  }
  ${media.md} {
    width: 500px;
    margin: 0 auto;
  }
`
