import styled from 'styled-components'
import { EditText } from '/components/EditText/EditText'

export const ActivityRow = styled.div`
`

export const ActivityTitle = styled(EditText)`
  margin-right: .5em;
`

export const Description = styled(EditText)`
  /* font-size: 12px; */
`