import axios from 'axios'

console.log('process.env.API_URL')
console.log(process.env.API_URL)
console.log('_')
const api = axios.create({
  baseURL: process.env.API_URL
})

export const insertSession = payload => api.post(`/session`, payload)
export const getAllSessions = () => api.get(`/sessions`)
export const updateSessionById = (id, payload) =>
  api.put(`/session/${id}`, payload)
export const deleteSessionById = id => api.delete(`/session/${id}`)
export const getSessionById = id => api.get(`/session/${id}`)

const apis = {
  insertSession,
  getAllSessions,
  updateSessionById,
  deleteSessionById,
  getSessionById
}

export default apis
