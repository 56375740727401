import actions from '/actions/actionTypes'
import apis from '/api/apis'
import { setLoading } from '/actions/commonActions'

export const createSession = () => async dispatch => {
  dispatch(setLoading(true))
  await apis.insertSession({ title: 'nya seshiòn' })
  dispatch(refreshSessions())
}

export const refreshSessions = () => async dispatch => {
  dispatch(setLoading(true))
  const result = await apis.getAllSessions()
  const receivedSessions = result.data.data
  dispatch(setSessions(receivedSessions))
  dispatch(setLoading(false))
}

export const setSessions = sessions => {
  return {
    type: actions.SET_SESSIONS,
    sessions
  }
}

export const setEditing = session => {
  return session
    ? {
        type: actions.SET_EDITABLE_SESSION,
        session
      }
    : {
        type: actions.END_EDITING,
      }
}
